.h1,
.h2,
.h3 {
  font-weight: 700;
}

.page-block-title {
  font-family: @font-branded;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 35px;
  display: block;
  line-height: 1.1;
  font-size: 1.8rem;
}
