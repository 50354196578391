.amlabel-position-wrapper .amasty-label-container {
  @media (max-width: @breakpoint-md) {
    font-size: 1rem;

    .amlabel-text {
      letter-spacing: 0;
    }
  }
}

