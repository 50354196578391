.fbc__result {
  svg {
    fill: @purple-lighter;
  }

  & > span {
    svg {
      fill: @black;
    }
  }
}

.fbc__rating.horizontal {
  .fbc__result {
    & > span {
      svg {
        fill: @primary;
      }
    }
  }
}

.aw-header .fbc__rating {
  .fbc__result {
    & > svg {
      fill: @white;
      opacity: 0.6;
    }

    & > span svg {
      fill: @white;
      opacity: 1;
    }
  }
}
