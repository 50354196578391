.aw-navigation {
  border-bottom-color: @purple-lighter;
}

@media(min-width: @tablet-max-width) {
  .aw-topmenu {
    &__dropdown {
      &-title {
        border-bottom-color: @purple-lighter;
      }
    }
  }
}
