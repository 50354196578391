.toolbar.toolbar-products,
.mpblog-post-index .pager {
  .pages[class] {
    .item.current > .page {
      background-color: @primary;
    }
    .item > .page:hover {
      background-color: @primary;
      color: @white;
    }
  }
}
