.checkout-cart-index {

  .cart-summary {
    border-color: @grey30;
    background-color: @grey;
  }
  .cart-summary > .title{
    border-color: @grey30;
  }
  .cart-totals[class] {
    .totals.sub,
    .totals-tax-summary {
      border-color: @grey30;
    }

    .grand.totals.incl {
      border-top-color: @grey30;
    }
  }
  .cart-summary {
    .totals.sub.excl,
    .totals.sub.incl,
    .totals-tax {
      border-color: @grey30;
    }
  }

  .cart-container {
    .item .product-image-wrapper {
      background: @grey;
    }

    hr {
      border-color: @purple-lighter
    }
  }
}
