@purple: #822c8e;
@purple-dark: darken(@purple, 10%);
@purple-light: #ac63b6;
@purple-lighter: #ecd4ef;
@black: #282828;
@green: #00a651;
@orange: #eca03f;

@grey: #f6f6f6;
@grey10: #e7e7e7;
@grey15: #f5f5f5;
@grey30: #e8e8e9;
@grey50: #dcdcdc;
@grey70: #d0d0d0;
@grey100: #bababa;
@grey200: #8d8d8d;
@grey300: #585858;

@primary: @purple;

// Fonts
@font-branded: 'Montserrat', 'DejaVu Sans', 'Verdana', sans-serif;
@font-body: 'Open Sans', sans-serif;

// Minicart
@minicart-qty-bg: @purple;

// Topbar
@topbar-bg: @black;
@topbar-accent-color: @purple;
@topbar-hover-color: @black;

// USP's
@usp-fill-color: @purple;

//Components
@component-border-bottom-color: @purple;

// Button
@btn-bg: @purple;
@btn-hover-bg: @purple-light;
@btn-outline-color: @purple-dark;

@btn-secondary-color: @purple;
@btn-secondary-outline-color: @purple;

// Footer
@footer-mid-bg: @grey;
@footer-mid-color: @black;

// Messages
@message-bg: @purple-light;
@message-border-color: @purple-light;
