.input-control {
  .input-control-wrapper {
    .minus, .plus {
      border-color: @purple-lighter;
    }

    .qty {
      border-color: @purple-lighter;
    }
  }
}
