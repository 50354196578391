@import '_accordion.less';
@import '_addtocart.less';
@import '_gallery.less';
@import '_amastylabels.less';

.catalog-product-view {
  .usps {
    background: transparent;
    border-top: 1px solid @purple-lighter;
    border-bottom: 1px solid @purple-lighter;
  }
}

.linked-products .linked-product.current {
  img {
    border: 2px solid @purple-lighter;
  }
}

.aw-tabs .nav.nav-tabs {
  border-color: @purple-lighter;
}

.product-tiles-title {
  border-bottom: 1px solid @purple-lighter;
}
