.row-product-tiles {
  .product-item__title {
    font-size: 1.3rem;
  }

  .product-item__price {
    .price-box[class]:not(.price-tier_price) .price-wrapper .price {
      color: @black;
    }
  }
}
