.blog-header-wrapper {
  .blog-header-inner {
    &:after {
      background: none;
    }
  }
}

.block-blog-related .related-title-wrapper h2 {
  .h2();
  border-color: @purple-lighter;
}
