.message[class] {
  &.notice,
  &.warning,
  &.progress,
  &.spinner,
  &.error,
  &.success,
  &.info {
    font-size: 1.1rem;
    font-weight: 500;
  }
}
