/** Load origins base **/
@import '../../vandentop/styling/theme.less';

@import 'components/_base.less';
@import 'components/_typography';
@import 'components/_usps';
@import 'components/_hero';
@import 'components/header/main.less';
@import 'components/footer/main.less';
@import 'components/category/main.less';
@import 'components/cart/main.less';
@import 'components/checkout/main.less';
@import 'components/blog/_header.less';
@import 'components/customer/main.less';
@import 'components/pdp/main.less';
@import 'components/_feedbackcompany.less';
@import 'components/_notifications.less';
@import 'components/_producttiles.less';
@import 'components/_slider.less';
@import 'components/_home.less';

/** override the settings **/
@import '_settings.less';
