@import '_filters.less';
@import '_toolbar.less';

.aw-category {
  &__links--wrapper {
    border-color: @purple-lighter;
  }
}

.tile__price {
  color: @black;
}

.subcategories__title {
  border-bottom: 1px solid @purple-lighter;
}
