.aw-header.scrollUp {
  transform: translateY(-130px);
}

.aw-logo-container .aw-logo {
  padding: 10px 0;
}

.aw-navigation {
  border-color: @purple-lighter;
}

.aw-search-container,
.aw-mobile-search {
  .block-search .control input {
    border-color: @purple-lighter;
  }
}

.aw-topmenu__additional li {
  border-color: @purple-lighter;
}

@import '_sooqr';
@import '_mainmenu';
