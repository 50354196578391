.aw-accordion {
  .accordion-item {
    &__link {
      border-top-color: @purple-lighter;

      &:not(.collapsed) {
        border-bottom-color: @purple-lighter;
      }
    }
  }
}
