@import '_success';

.onestepcheckout-index-index {
  .order-summary {
    background-color: @grey;
    border-color: @grey30;

    .step-title {
      background-color: @grey;
      border-color: @grey30;
    }
  }

  .table-totals[class] {
    .totals.sub,
    .totals.shipping,
    .totals-tax-summary {
      border-color: @grey30;
    }

    .grand.totals.incl {
      border-top-color: @grey30;
    }
  }

  .shipping-address-items {
    .shipping-address-item {
      border-color: @grey30;

      button.edit-address-link {
        color: @primary;
      }

      &.selected-item {
        border-color: @primary;
      }
    }
  }
}

