.footer-usps .footer-usp {
  svg circle {
    fill: @purple;
  }
}

.aw-footer {
  .footer-top {
    border-color: @purple-lighter;

    .tagline {
      font-weight: 500;
    }
  }
}

.footer-menu__item {
  @media (max-width: @breakpoint-sm) {
    a {
      border-color: @grey30;
    }
  }
}

.footer-menu__title {
  font-size:1.2rem;
  font-weight: 700;
}

.footer-bottom {
  .footer-certifications,
  .footer-bottom-menu {
    border-color: @purple-lighter;
  }
}

.footer-middle .footer-socials svg {
  fill: @black;
}
